import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { ANALYTICS } from 'src/app/appConstants';
declare var gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'myportfolio';
  showCookieConsent: boolean = true;
  analytics = ANALYTICS;
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', this.analytics, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
    const consent = localStorage.getItem('cookieConsent');
    this.showCookieConsent = consent !== 'true';
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  goToProject() {
    this.router.navigate(['/project']);
  }

  goToContact() {
    this.router.navigate(['/contact']);
  }
  acceptCookies() {
    localStorage.setItem('cookieConsent', 'true');
    this.showCookieConsent = false;
  }
}
