<div class="container">
  <nav class="navMenu">
    <button class="menuItem" (click)="goToHome()">Home</button>
    <button class="menuItem" (click)="goToProject()">Projects</button>
    <button class="menuItem" (click)="goToContact()">Contact</button>
  </nav>
  <div class="centered-content">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="cookie-consent" *ngIf="showCookieConsent">
  <p>We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of cookies.</p>
  <button (click)="acceptCookies()">Accept</button>
</div>
