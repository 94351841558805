import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var particlesJS: any;

@Component({
  selector: 'app-project-home',
  templateUrl: './project-home.component.html',
  styleUrls: ['./project-home.component.css']
})
export class ProjectHomeComponent implements OnInit, AfterViewInit {
  skills = [
    { name: 'Java', image: 'assets/java.png' },
    { name: 'Angular', image: 'assets/angular.png' },
    { name: 'HTML', image: 'assets/html.png' },
    { name: 'CSS', image: 'assets/css.png' },
    { name: 'JavaScript', image: 'assets/javascript.png' },
    { name: 'Node.js', image: 'assets/node.png' },
    { name: 'React', image: 'assets/react.png' },
    { name: 'Git', image: 'assets/git.png' },
    { name: 'Jenkins', image: 'assets/jenkins.png' },
    { name: 'Docker', image: 'assets/docker.png' }
  ];

  projects = [
    {
      name: 'Music Store App',
      subtitle: 'Online Platform for Purchasing Music',
      description: 'An online platform where users can browse, listen, and purchase music content. The app integrates with paypal payment system and ensures a seamless experience from discovering music to completing a purchase.',
      techStack: ['Java', 'Spring Boot','Angular', 'AWS', 'MySQL'],
      image: 'assets/sc5.png',
      demoLink: 'https://itzhenny.netlify.app/',
      repoLink: 'https://github.com/Henockb3/StoreApp'
    },
    {
      name: 'Live Aircraft Tracking System',
      subtitle: 'Real-time Aircraft Position Tracking using RTL-SDR',
      description: 'A real-time aircraft tracking system using Angular and Java. Data is captured via an RTL-SDR ADS-B receiver connected to a Raspberry Pi, processed by a Java backend, and visualized on a Leaflet map in the Angular frontend. Jenkins was utilized for continuous integration and deployment, automating the process of deploying updates to Heroku.',
      techStack: ['Java', 'Angular', 'Raspberry Pi', 'Leaflet', 'Jenkins', 'Heroku'],
      image: 'assets/sc6.png',
      demoLink: 'https://trackerui.netlify.app/',
      repoLink: 'https://github.com/Henockb3/TrackerUI'
    },
    {
      name: 'Buisness Website',
      subtitle: 'Professional Website for Hilmey Transportation LLC',
      description: 'A modern and responsive website developed for Hilmey Transportation to showcase their services and products. The website features a user-friendly design, optimized for performance and accessibility, and includes an integrated contact form, service pages, and a booking section',
      techStack: ['React', 'HTML', 'CSS', 'JavaScript'],
      image: 'assets/hilmey.png',
      demoLink: 'https://hilmeytransportation.com',
      repoLink: 'https://github.com/Henockb3/HilmeyUi'
    },
    {
      name: 'Crypto Web App',
      subtitle: 'Real-time Cryptocurrency Trading Simulator',
      description: 'A real-time cryptocurrency trading platform that simulates market conditions using live currency data from external APIs. This app allows users to trade various cryptocurrencies, monitor their investments, and track the market trends in real-time, offering a realistic trading experience without financial risk.',
      techStack: ['Java', 'Spring', 'Angular', 'AWS', 'MySQL'],
      image: 'assets/sc2.png',
      demoLink: 'https://cryptoapphome.netlify.app/',
      repoLink: 'https://github.com/Henockb3/CryptoApp'
    },
    
  ];

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const setParticlesHeight = () => {
      const particlesElement = document.getElementById('particles-js-projects');
      if (particlesElement) {
        particlesElement.style.height = `${window.innerHeight}px`;
      }
    };
  
    window.addEventListener('resize', setParticlesHeight);
    setParticlesHeight();
    this.loadParticles();
  }

  loadParticles(): void {
    particlesJS('particles-js-projects', {
      "particles": {
        "number": {
          "value": 80,
          "density": {
            "enable": true,
            "value_area": 800
          }
        },
        "color": {
          "value": "#ffffff"
        },
        "shape": {
          "type": "edge",
          "stroke": {
            "width": 0,
            "color": "#000000"
          }
        },
        "opacity": {
          "value": 0.5,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 3,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 40,
            "size_min": 0.1,
            "sync": false
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 150,
          "color": "#ffffff",
          "opacity": 0.4,
          "width": 1
        },
        "move": {
          "enable": true,
          "speed": 6,
          "direction": "none",
          "random": false,
          "straight": false,
          "out_mode": "out",
          "bounce": false,
          "attract": {
            "enable": false,
            "rotateX": 600,
            "rotateY": 1200
          }
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": true,
            "mode": "grab"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 400,
            "line_linked": {
              "opacity": 1
            }
          },
          "bubble": {
            "distance": 400,
            "size": 40,
            "duration": 2,
            "opacity": 8,
            "speed": 3
          },
          "repulse": {
            "distance": 200,
            "duration": 0.4
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 2
          }
        }
      },
      "retina_detect": true
    });
  }
}
