import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactHomeComponent } from './contact/contact-home/contact-home.component';
import { HomeHomeComponent } from './home/home-home/home-home.component';
import { ProjectHomeComponent } from './project/project-home/project-home.component';

const routes: Routes = [
  {path:'project', component:ProjectHomeComponent},
  {path :'contact',component: ContactHomeComponent},
  {path:'home', component:HomeHomeComponent},
  { path: '' , redirectTo:'/home', pathMatch:'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
