import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectRoutingModule } from './project-routing.module';
import { ProjectHomeComponent } from './project-home/project-home.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ProjectHomeComponent],
  imports: [
    CommonModule,
    ProjectRoutingModule,
    MatGridListModule, 
    MatCardModule,
    MatButtonModule
    

  ]
})
export class ProjectModule { }
