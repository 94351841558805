<div id="particles-js-contact"></div>
<div contact-content>
  <div class="contact-container">
    <h2>Ask me a Question</h2>
    <form [action]="submissionUrl" method="POST" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input type="text" name="name" placeholder="Your Name" required />
      </div>
      <div class="form-group">
        <input type="email" name="email" placeholder="Your Email" required />
      </div>
      <div class="form-group">
        <textarea name="message" placeholder="Your Message" rows="4" required></textarea>
      </div>
      <button type="submit" [disabled]="isLoading">
        <span *ngIf="!isLoading">Send Message</span>
        <span *ngIf="isLoading" class="spinner"></span>
      </button>
    </form>
  </div>
</div>