import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { ContactModule } from './contact/contact.module';
import { ProjectModule } from './project/project.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { GtagModule } from 'angular-gtag';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HomeModule,
    ContactModule,
    ProjectModule,
    MatGridListModule, 
    MatCardModule,
    MatButtonModule,
    GtagModule.forRoot({ trackingId: 'G-DE234RM69Z' }),
    
  
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
