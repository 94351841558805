<div id="particles-js-projects"></div>
<div class="projects-content">
  <div class="skills-container">
    <div *ngFor="let skill of skills" class="skill-item">
      <img [src]="skill.image" alt="{{ skill.name }}" />
      <p>{{ skill.name }}</p>
    </div>
  </div>
  <h2>Projects</h2>
  <div class="projects-container">
    <div *ngFor="let project of projects" class="project-card">
      <img [src]="project.image" alt="{{ project.name }}" class="project-image" />
      <div class="project-info">
        <h3>{{ project.name }}</h3>
        <h4>{{ project.subtitle }}</h4>
        <p>{{ project.description }}</p>
        <ul class="tech-stack">
          <li *ngFor="let tech of project.techStack">{{ tech }}</li>
        </ul>
        <div class="project-actions">
          <a [href]="project.demoLink" target="_blank" class="button live-demo">Live Demo</a>
          <a [href]="project.repoLink" target="_blank" class="button github-repo">GitHub Repo</a>
        </div>
      </div>
    </div>
  </div>
</div>

